import React, { Component } from 'react';
import Parser from 'html-react-parser';

const playlist1 = '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1150505534&color=%2321201d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/mich-z" title="MiZ" target="_blank" style="color: #cccccc; text-decoration: none;">MiZ</a> · <a href="https://soundcloud.com/mich-z/sets/waking-miz-music" title="Waking MiZ Music" target="_blank" style="color: #cccccc; text-decoration: none;">Waking MiZ Music</a></div>';
// const playlist2 = '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/843834644&color=%2321201d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/mich-z" title="MiZ" target="_blank" style="color: #cccccc; text-decoration: none;">MiZ</a> · <a href="https://soundcloud.com/mich-z/sets/house_is_me" title="House_IS_Me" target="_blank" style="color: #cccccc; text-decoration: none;">House_IS_Me</a></div>';

class Music extends Component {
  render() {
    return (
      <section id="music">
      <div className="row">

         <h1>Escuchas Mi Música</h1>

         <div className="twelve columns collapsed">
            <div id="player-wrapper">
               {Parser(playlist1)}
            </div>
         </div>

      </div>

   </section>
    );
  }
}

export default Music;
